import React, { useEffect, useState } from "react";
import "./CatchupSection.scss";
import Button from "@common/Button/Button";
import arrowIcon from "@images/ic-arrow-right.svg";
import {
    MAIN_BUTTON,
    CATCHUP_SECTION_LEFT,
    CATCHUP_SECTION_CENTER,
} from "@constants";
import { navigate } from "gatsby";
import catchupDesktop from "@images/catchup-image.svg";
import catchupMobile from "@images/catchup-image-mobile.svg";
import catchupTablet from "@images/catchup-image-tablet.svg";
import Image from "@common/Image/Image";
import useWindowDimensions from "@hooks/useWindowDimensions";
import classNames from "classnames";
import { TABLET_MIN_WIDTH, TABLET_MAX_WIDTH } from "../../../constants";

const CatchupSection = ({
    buttonText,
    title,
    message,
    handleClick,
    catchupSectionAlign,
    viewWithImage,
    hasIcon,
}) => {
    const [catchupImage, setCatchupImage] = useState();
    const { width } = useWindowDimensions();
    const isMobile = width < TABLET_MIN_WIDTH;
    const isTablet = !isMobile && width <= TABLET_MAX_WIDTH;

    const headingClassName = classNames("catchup-section__heading", {
        "catchup-section__heading--center":
            catchupSectionAlign === CATCHUP_SECTION_CENTER,
    });

    const wrapperClassName = classNames("catchup-section", {
        "catchup-section-image": viewWithImage === true,
    });

    useEffect(() => {
        if (isTablet) {
            setCatchupImage(catchupTablet);
        } else {
            setCatchupImage(catchupDesktop);
        }
        if (isMobile) {
            setCatchupImage(catchupMobile);
        }
    }, [isMobile, isTablet]);

    return (
        <div className={wrapperClassName}>
            {viewWithImage ? (
                <>
                    <div className="catchup-section-image__catchup-img-container">
                        <Image
                            src={catchupImage}
                            className="catchup-section-image__catchup-img"
                            alt="Catchup image"
                        />
                    </div>
                    <div className="catchup-section-image__info-wrapper">
                        <h4>{title}</h4>
                        <div className="catchup-section-image__btn-container">
                            <Button
                                text={buttonText}
                                handleClick={handleClick}
                                type={MAIN_BUTTON}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className={headingClassName}>
                        <p className="catchup-section__headind-title">
                            {title}
                        </p>
                        <p className="catchup-section__headind-message">
                            {message}
                        </p>
                    </div>
                    <div className="catchup-section__btn-container">
                        <Button
                            text={buttonText}
                            svgPath={
                                hasIcon &&
                                window.innerWidth > TABLET_MAX_WIDTH &&
                                arrowIcon
                            }
                            handleClick={handleClick}
                            type={MAIN_BUTTON}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

CatchupSection.defaultProps = {
    buttonText: "Contact us",
    title: "Would you like to connect?",
    handleClick: () => navigate("/contact-us"),
    message:
        "Write to us in the chat or complete contact form, and we will contact you within 24 hours.",
    catchupSectionAlign: CATCHUP_SECTION_LEFT,
    hasIcon: true,
};

export default CatchupSection;
