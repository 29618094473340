import styled, { css } from "styled-components"
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants"

export const StyledCaseStudyHeaderLogo = styled.img`
  background-repeat: no-repeat;
  background-size: 100% 3vh;
  max-height: 3.125rem;

  ${({ alt }) =>
    alt === "lulu-light product logo" &&
    css`
      max-height: 11.125rem !important;
      
      @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        max-height: 5.125rem !important;
      }
    `}

  ${({ alt }) =>
    alt === "jota product logo" &&
    css`
      max-width: 2.625rem !important;
    `}
`
