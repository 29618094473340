import React, { useMemo } from "react";
import useWindowDimensions from "@hooks/useWindowDimensions";
import "./CaseStudyHeader.scss";
import { Link } from "gatsby";
import { StyledCaseStudyHeaderLogo } from "./styled"

const CaseStudyHeader = ({ caseStudy }) => {
    const {
        logo,
        title,
        weMade,
        website,
        websiteLinkClickable,
        industry,
        technologies,
        founded,
        location,
        partnershipPeriod,
        teamSize,
        metaUrl,
    } = caseStudy;

    const { isDesktop } = useWindowDimensions();

    const getInfoWidth = useMemo(() => {
        if (!isDesktop) {
            return;
        }
        if (metaUrl === "ar-application") {
            return "30%";
        }
        if (metaUrl === "carbon") {
            return "42%";
        }
        return null;
    }, [isDesktop, metaUrl]);

    return (
        <div className="case-study-header__info">
            <StyledCaseStudyHeaderLogo
                src={logo.url}
                alt={`${metaUrl} product logo`}
            />
            <h1>{title}</h1>
            <div>
                <div className="case-study-header__main-info-container">
                    <div className="case-study-header__main-info">
                        <div className="case-study-header__main-info-text">
                            We made
                            <p>{weMade}</p>
                        </div>
                        <div className="case-study-header__main-info-text">
                            Website
                            <p>
                                {(website === "NDA" || websiteLinkClickable === false) ? (
                                    website
                                ) : (
                                    <Link
                                        to={
                                            website.includes("http")
                                                ? website
                                                : `https://${website}`
                                        }
                                        target="_blank"
                                        rel="nofollow noreferrer"
                                        className="case-study-header__main-info-link"
                                    >
                                        {website}
                                    </Link>
                                )}
                            </p>
                        </div>
                    </div>
                    <div
                        className="case-study-header__main-info"
                        style={{
                            width: getInfoWidth,
                        }}
                    >
                        <div className="case-study-header__main-info-text">
                            Industry
                            <p>{industry}</p>
                        </div>
                        <div className="case-study-header__main-info-text">
                            Technologies
                            <p>{technologies}</p>
                        </div>
                    </div>
                    <div className="case-study-header__main-info">
                        <div className="case-study-header__main-info-text">
                            Founded
                            <p>{founded}</p>
                        </div>
                        <div className="case-study-header__main-info-text">
                            Location
                            <p>{location}</p>
                        </div>
                    </div>
                    <div className="case-study-header__main-info">
                        <div className="case-study-header__main-info-text">
                            Partnership period
                            <p>{partnershipPeriod}</p>
                        </div>
                        <div className="case-study-header__main-info-text">
                            Team size
                            <p>{teamSize}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CaseStudyHeader;
