import React from "react";
import { graphql } from "gatsby";
import Layout from "@common/Layout/Layout";
import CaseStudy from "@components/CaseStudy/CaseStudy";
import SEO from "../components/Common/SEO/SEO";

const CaseStudyTemplate = ({ path, data }) => {
    const { strapiCaseStudies: caseStudy } = data;

    return (
        <>
            <SEO
                title={caseStudy.metaTitle}
                titleTemplate="Impressit"
                description={caseStudy.metaDescription}
                image={caseStudy.OpenGraphImage.url}
                path={path}
            />
            <Layout>
                <CaseStudy caseStudy={caseStudy} />
            </Layout>
        </>
    );
};

export default CaseStudyTemplate;

export const query = graphql`
    query CaseStudiesQuery($id: String) {
        strapiCaseStudies(id: { eq: $id }) {
            backlightColor
            eighthSubsectionText
            fifthSubsectionImage {
                url
            }
            fifthSubsectionText
            firstSubsectionImage {
                url
            }
            firstSubsectionText
            founded
            fourthSubsectionText
            id
            industry
            location
            logo {
                url
            }
            metaDescription
            metaTitle
            metaUrl
            partnershipPeriod
            secondSubsectionImage {
                url
            }
            secondSubsectionText
            seventhSubsectionImage {
                url
            }
            seventhSubsectionText
            sixthSubsectionImage {
                url
            }
            sixthSubsectionText
            teamSize
            technologies
            thirdSubsectionText
            thirdSubsectionImage {
                url
            }
            title
            weMade
            website
            websiteLinkClickable
            wideImage {
                url
            }
            OpenGraphImage {
                url
            }
        }
    }
`;
